<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-03-30 14:37:36
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-08 15:17:29
-->
<template>
  <div id="app">
    <!-- <router-view/> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不缓存的页面，不缓存$route.meta.keepAlive为false的组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
  beforeCreate() {

  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  font-family: "Microsoft Yahei Regular", "SF Pro SC", "SF Pro Display",
    "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  .warning-row{
      background: #e6a23c;
  }
  .danger-row{
      background: #f56c6c;
  }
  .info-row{
      background: #dddddd;
  }
}
.flex-sb {
  display: flex;
  justify-content: space-between;
}

.flex-sbc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-fec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-fe {
  display: flex;
  justify-content: flex-end;
}

.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-c {
  display: flex;
  justify-content: center;
}

.flex-wc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-w {
  display: flex;
  flex-wrap: wrap;
}

.flex-coc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-ce {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.flex-co {
  display: flex;
  flex-direction: column;
}

</style>
