/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-09 09:31:51
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-05-11 14:16:34
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-09 09:31:51
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-05-11 14:15:31
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routerWeb = [
  {
    path: '/',
    name:"home",
    component: resolve => require(['../pages/Web/index'], resolve),
  },{
    path: '/webRegisterTry',
    name:"webRegisterTry",
    component: resolve => require(['../pages/Web/webRegisterTry'], resolve),
  },{
    path: '/webOpnePro',
    name:"webOpnePro",
    component: resolve => require(['../pages/Web/webOpnePro'], resolve),
  },{
    path: '/webSelectPackage',
    name:"webSelectPackage",
    component: resolve => require(['../pages/Web/webSelectPackage'], resolve),
  },{
    path: '/webDemonstration',
    name:"webDemonstration",
    component: resolve => require(['../pages/Web/webDemonstration'], resolve),
  },{
    path: '/webProduct',
    name:"webProduct",
    component: resolve => require(['../pages/Web/webProduct'], resolve),
  },{
    path: '/webAboutUs',
    name:"webAboutUs",
    component: resolve => require(['../pages/Web/webAboutUs'], resolve),
  },,{
    path: '/webPrice',
    name:"webPrice",
    component: resolve => require(['../pages/Web/webPrice'], resolve),
  },
]

const routerPc = [
  {
    path: '/',
    name:"home",
    component: resolve => require(['../pages/Pc/index'], resolve),
  },
  // {
  //   path: '/webRegisterTry',
  //   name:"webRegisterTry",
  //   component: resolve => require(['../pages/Web/webRegisterTry'], resolve),
  // },{
  //   path: '/webOpnePro',
  //   name:"webOpnePro",
  //   component: resolve => require(['../pages/Web/webOpnePro'], resolve),
  // },{
  //   path: '/webSelectPackage',
  //   name:"webSelectPackage",
  //   component: resolve => require(['../pages/Web/webSelectPackage'], resolve),
  // },{
  //   path: '/webDemonstration',
  //   name:"webDemonstration",
  //   component: resolve => require(['../pages/Web/webDemonstration'], resolve),
  // },{
  //   path: '/webProduct',
  //   name:"webProduct",
  //   component: resolve => require(['../pages/Web/webProduct'], resolve),
  // },{
  //   path: '/webAboutUs',
  //   name:"webAboutUs",
  //   component: resolve => require(['../pages/Web/webAboutUs'], resolve),
  // },
]

var routes = []
let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
if (navigator.userAgent.match(Adaptive)) {
    routes = routerWeb
} else {
    routes = routerPc
}

const router = new VueRouter({
  routes
})

export default router
