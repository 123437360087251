/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-03-30 14:37:36
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-05-09 09:38:08
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'; 
import 'element-ui/lib/theme-chalk/index.css';
import './assets/elementCss/dialogCss.css'
import axios from 'axios';

Vue.prototype.$axios = axios;

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
